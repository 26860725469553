import { AppVersionFormInputs, AppVersionResponse } from '../types';
import { api } from '../../../shared/api/api.config.ts';

export const updateAppVersion = async (data: AppVersionFormInputs): Promise<AppVersionResponse> => {
	const response = await api.post<AppVersionResponse>(
		'/app-version/admin/update',
		data,
	);
	return response.data;
};

export const getAppVersion = async (platform: 'ios' | 'android'): Promise<AppVersionResponse> => {
	const response = await api.get<AppVersionResponse>(`/app-version/admin?platform=${platform}`);
	return response.data;
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import './sidebar.scss';
import { logout, removeTokens } from '../../api/api.config.ts';
import Cookies from 'js-cookie';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';

const NavBar: React.FC = () => {
	const navigate = useNavigate();
	const refreshToken = Cookies.get('refreshToken');

	const handleLogout = async () => {
		if (refreshToken) {
			await logout(refreshToken);
		}
		removeTokens();
		navigate('/login');
	};

	return (
		<nav className="aside-container">
			<ul>
				<li className="main-menu-list">
					<button className="menu-item" onClick={() => navigate('/')}>
						<SupervisedUserCircleIcon />
						<span>Пользователи</span>
					</button>
					<button className="menu-item" onClick={() => navigate('/articles')}>
						<LibraryBooksIcon />
						<span>Статьи</span>
					</button>
					<button className="menu-item" onClick={() => navigate('/app-version')}>
						<AppSettingsAltIcon />
						<span>Версия приложения</span>
					</button>
					<button className="menu-item" onClick={handleLogout}>
						<ExitToAppIcon />
						<span>Выйти</span>
					</button>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;

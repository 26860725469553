import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
	TextField,
	Button,
} from '@mui/material';
import { AppVersionFormInputs } from './types';
import { updateAppVersion, getAppVersion } from './api/appVersion.api.ts';
import { toast } from 'material-react-toastify';
import { FC, useEffect } from 'react';
import './app-version.scss';
// Форма для обновления версии приложения
const AppVersionForm: FC<{ platform: 'ios' | 'android' }> = ({ platform }) => {
	const queryClient = useQueryClient();

	// Используем useForm для управления формой
	const { handleSubmit, control, setValue } = useForm<AppVersionFormInputs>({
		defaultValues: {
			platform,
			version: '',
			update_url: '',
			release_notes: '',
		},
	});

	// Запрос на получение текущей версии для платформы
	const { data, isLoading } = useQuery(
		{
			queryKey: ['appVersion', platform],
			queryFn: () => getAppVersion(platform),
		},
	);
	useEffect(() => {
		if (data) {
			setValue('platform', data?.platform as 'ios' | 'android');
			setValue('version', data?.version);
			setValue('update_url', data?.update_url);
			setValue('release_notes', data?.release_notes);
		}
	}, [data]);
	// Мутация для обновления версии
	const mutation = useMutation({
		mutationFn: (data: AppVersionFormInputs) => updateAppVersion(data),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['appVersion', platform] });
			toast.dark(`Версия приложения для ${platform} успешно обновлена`);
		},
		onError: (error: any) => {
			console.error('Ошибка при обновлении версии', error);
			toast.dark(`Не удалось обновить версию для ${platform}`);
		},
	});

	// Обработка отправки формы
	const onSubmit: SubmitHandler<AppVersionFormInputs> = (data) => {
		mutation.mutate(data);
	};

	return (
		<div style={{ marginBottom: '20px' }}>
			<p style={{ fontSize: '18px', fontWeight: 'bold' }}>
				Обновление версии для {platform === 'ios' ? 'iOS' : 'Android'}
			</p>
			{isLoading ? (
				<p>Загрузка...</p>
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					{/* Поле версии */}
					<Controller
						name="version"
						control={control}
						render={({ field }) => (
							<TextField {...field} label="Версия" fullWidth margin="normal" required />
						)}
					/>

					{/* Поле ссылки на обновление */}
					<Controller
						name="update_url"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								label="Ссылка на обновление"
								fullWidth
								margin="normal"
								required
							/>
						)}
					/>

					{/* Поле для примечаний к релизу */}
					<Controller
						name="release_notes"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								label="Примечания к релизу"
								fullWidth
								margin="normal"
								multiline
								rows={4}
							/>
						)}
					/>

					{/* Кнопка отправки */}
					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={mutation.isPending}
					>
						{mutation.isPending ? 'Обновление...' : 'Обновить версию'}
					</Button>
				</form>
			)}
		</div>
	);
};

export const AppVersionUpdateForm: FC = () => {
	return (
		<div className={'wrapper'}>
			<div style={{ display: 'flex', gap: '20px' }}>
				<div style={{ flex: 1 }}>
					{/* Форма для iOS */}
					<AppVersionForm platform="ios" />
				</div>
				<div style={{ flex: 1 }}>
					{/* Форма для Android */}
					<AppVersionForm platform="android" />
				</div>
			</div>
		</div>
	);
};

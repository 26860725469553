import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface PrivateRouteProps {
	component: React.ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component }) => {
	const accessToken = Cookies.get('accessToken');

	if (!accessToken) {
		return <Navigate to="/login" />;
	}

	return <Component />;
};

export default PrivateRoute;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import imageCompression from 'browser-image-compression'; // Импорт библиотеки для сжатия изображений
import { Chip, MenuItem, InputLabel, Select, Box, FormControl, Button } from '@mui/material'; // Material-UI компоненты
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // Иконка для загрузки файлов
import CustomInput from '../../shared/components/CustomInput/CustomInput.tsx'; // Ваш кастомный компонент
import './article-form.scss';
import { BoldItalicUnderlineToggles, headingsPlugin, MDXEditor, toolbarPlugin, UndoRedo } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import './editor.scss';

interface ArticleFormProps {
	onSubmit: (formData: FormData) => void;
	initialData?: {
		title: string;
		content: string;
		imageUrl?: string | null;
		summary: string;
		gameKeys?: string; // Строка ключей
	};
	className?: string;
	creationMode?: boolean;
}

interface FormDataType {
	title: string;
	content: string;
	image: FileList | null;
	summary: string;
	gameKeys: string[];
}

const gameKeysOptions = [
	{ value: 'fastCount', name: 'Быстрый счет' },
	{ value: 'fastReplace', name: 'Символика' },
	{ value: 'suitcase', name: 'Чемодан' },
	{ value: 'decoding', name: 'Ассоциации' },
];

export const ArticleForm: React.FC<ArticleFormProps> = ({ onSubmit, initialData, className, creationMode }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		setValue,
		setError,
		clearErrors,
	} = useForm<FormDataType>({
		defaultValues: {
			title: initialData?.title || '',
			content: initialData?.content || '',
			summary: initialData?.summary || '',
			image: null,
			gameKeys: initialData?.gameKeys ? initialData.gameKeys.split(',') : [], // Преобразуем строку в массив
		},
	});

	const fileUrl = import.meta.env.VITE_FILE_URL || 'http://localhost:3000';
	const initialGameKeys = initialData?.gameKeys ? initialData.gameKeys.split(',') : [];
	const [selectedGameKeys, setSelectedGameKeys] = useState<string[]>(initialGameKeys);
	const [imagePreview, setImagePreview] = useState<string | null>(initialData?.imageUrl ? `${fileUrl}${initialData.imageUrl}` : null); // Для превью
	const [selectedImage, setSelectedImage] = useState<File | null>(null); // Для выбранного изображения
	const [editorContent, setEditorContent] = useState<string>(initialData?.content || 'Ваш контент');

	const handleFormSubmit = async (data: FormDataType) => {
		if (!selectedImage && !initialData?.imageUrl) {
			setError('image', { type: 'manual', message: 'Изображение обязательно' });
			return;
		}

		const formData = new FormData();
		formData.append('title', data.title);
		formData.append('content', editorContent); // Добавляем содержимое редактора
		formData.append('summary', data.summary);

		// Преобразуем массив gameKeys в строку для отправки на сервер
		const gameKeysString = selectedGameKeys.length > 0 ? selectedGameKeys.join(',') : '';
		formData.append('gameKeys', gameKeysString);

		if (selectedImage) {
			try {
				// Настройки для сжатия
				const options = {
					maxSizeMB: 1, // Максимальный размер изображения в мегабайтах
					maxWidthOrHeight: 1024, // Максимальная ширина или высота изображения
					useWebWorker: true, // Использование веб-воркера
				};

				// Сжимаем изображение
				const compressedImage = await imageCompression(selectedImage, options);
				formData.append('image', compressedImage); // Добавляем сжатое изображение в FormData
			} catch (error) {
				console.error('Ошибка при сжатии изображения:', error);
			}
		}

		onSubmit(formData);

		if (creationMode) {
			reset();
			setImagePreview(null); // Очищаем превью после отправки формы
			setSelectedImage(null); // Очищаем выбранное изображение после отправки формы
		}

		clearErrors('image');
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setSelectedImage(file); // Сохраняем выбранное изображение в состоянии

			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target?.result) {
					setImagePreview(e.target.result as string); // Устанавливаем локальное превью
				}
			};
			reader.readAsDataURL(file);

			// Очищаем ошибку, если изображение было загружено
			clearErrors('image');
		}
	};

	return (
		<div className={className}>
			<div className={'mb-2 title-form'}>{initialData ? 'Обновление статьи' : 'Создание статьи'}</div>
			<form onSubmit={handleSubmit(handleFormSubmit)}>

				<CustomInput
					type="text"
					placeholder="Заголовок"
					label={'Заголовок'}
					{...register('title', { required: 'Заголовок обязателен' })}
					errorText={errors.title ? errors.title.message : undefined}
				/>

				<CustomInput
					type="text"
					label={'Краткое описание'}
					placeholder="Краткое описание"
					{...register('summary', { required: 'Краткое описание обязательно' })}
					errorText={errors.summary ? errors.summary.message : undefined}
				/>

				<label>Контент</label>
				<MDXEditor
					className="dark-theme dark-editor mt-2 mb-4"
					markdown={editorContent}
					onChange={(newContent) => {
						setEditorContent(newContent);
						setValue('content', newContent);
					}}
					plugins={[headingsPlugin(), toolbarPlugin({
						toolbarContents: () => (
							<>
								<UndoRedo />
								<BoldItalicUnderlineToggles />
							</>
						),
					})]}
				/>

				<FormControl fullWidth variant="outlined" className="mb-4">
					<InputLabel>Привязка к играм</InputLabel>
					<Select
						multiple
						value={selectedGameKeys}
						onChange={(e) => setSelectedGameKeys(e.target.value as string[])}
						label="Привязка к играм"
						renderValue={(selected) => (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
								{(selected as string[]).map((value) => (
									<Chip key={value} label={gameKeysOptions.find(option => option.value === value)?.name || value} />
								))}
							</Box>
						)}
						variant={'outlined'}>
						{gameKeysOptions.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<div className={'my-4'}>
					<Button
						component="label"
						role={undefined}
						variant="contained"
						tabIndex={-1}
						startIcon={<CloudUploadIcon />}
					>
						Загрузить изображение
						<input
							accept="image/*"
							type="file"
							onChange={handleImageChange}
							hidden
						/>
					</Button>
				</div>

				{/* Если есть превью картинки, показываем его */}
				{imagePreview && (
					<div className="image-preview my-2">
						<img src={imagePreview} alt="Preview" className="mt-2 w-[300px] rounded-sm" />
					</div>
				)}

				<Button variant={'contained'} type="submit">
					{initialData ? 'Обновить статью' : 'Создать статью'}
				</Button>
			</form>
		</div>
	);
};

import { api } from '../../../shared/api/api.config.ts';
import { Article } from '../types';

// Интерфейс для пагинированного ответа
export interface PaginatedArticles {
	articles: Article[];
	total: number;
	page: number;
	lastPage: number;
}

// Интерфейс для ответов создания/обновления статьи
export interface ArticleResponse {
	article: Article;
}

// Интерфейс для успешного удаления статьи
export interface DeleteResponse {
	message: string;
}

// Интерфейс для изменения видимости статьи
export interface VisibilityResponse {
	article: Article;
}

// Получение статей с пагинацией
export const fetchArticles = async (
	page: number,
	limit: number = 10,
): Promise<PaginatedArticles> => {
	const response = await api.get<PaginatedArticles>(`/articles/admin`, {
		params: {
			page,
			limit,
			visibleOnly: false,
		},
	});
	return response.data;
};

// Создание новой статьи
export const createArticle = async (formData: FormData): Promise<ArticleResponse> => {
	const response = await api.post<ArticleResponse>('/articles', formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
};

// Обновление существующей статьи
export const updateArticle = async (id: number, formData: FormData): Promise<ArticleResponse> => {
	const response = await api.patch<ArticleResponse>(`/articles/${id}`, formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
};

// Удаление статьи
export const deleteArticle = async (id: number): Promise<DeleteResponse> => {
	const response = await api.delete<DeleteResponse>(`/articles/${id}`);
	return response.data;
};

// Изменение видимости статьи
export const toggleArticleVisibility = async (id: number, isVisible: boolean): Promise<VisibilityResponse> => {
	const response = await api.patch<VisibilityResponse>(`/articles/${id}/visibility`, { isVisible });
	return response.data;
};

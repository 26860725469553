export const getImgUrl = (avatar: string | null | undefined, gender: string | null | undefined): string => {
	if (avatar && avatar.startsWith('data:image')) {
		return avatar;
	}

	if (avatar != null && avatar !== 'null' && avatar !== 'undefined') {
		return `data:image/png;base64,${avatar}`;
	}

	return gender === 'male'
		? '/assets/images/male.png'
		: gender === 'female'
			? '/assets/images/female.png'
			: '/assets/images/male.png';
};

import axios from 'axios';
import Cookies from 'js-cookie';

export const login = async (email: string, password: string) => {
	const { data } = await api.post('/admin/login', { email, password });
	Cookies.set('accessToken', data.accessToken, { expires: 1, secure: true });
	Cookies.set('refreshToken', data.refreshToken, { expires: 7, secure: true });
	return data;
};

export const logout = async (refresh_token: string) => {
	await api.post('/admin/logout', { refresh_token });
};

export const removeTokens = async () => {
	Cookies.remove('accessToken');
	Cookies.remove('refreshToken');
};


export const refreshTokens = async () => {
	const refreshToken = Cookies.get('refreshToken');
	if (refreshToken) {
		const { data } = await api.post('/refresh_token', { refresh_token: refreshToken });
		Cookies.set('accessToken', data.accessToken, { expires: 1, secure: true });
		Cookies.set('refreshToken', data.refreshToken, { expires: 7, secure: true });
		return data;
	}
};

export const api = axios.create({
	baseURL: import.meta.env.VITE_API_URL || 'http://localhost:3000/api',
});

api.interceptors.request.use((config) => {
	const token = Cookies.get('accessToken');
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

api.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;

		if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
			originalRequest._retry = true;

			try {
				const { accessToken } = await refreshTokens();
				Cookies.set('accessToken', accessToken);
				originalRequest.headers.Authorization = `Bearer ${accessToken}`;
				return api(originalRequest);
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
			} catch (error) {
				removeTokens();  // Удаление токенов, чтобы предотвратить дальнейшие циклы
				window.location.href = '/login';
			}
		}

		return Promise.reject(error);
	},
);


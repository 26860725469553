import React from 'react';
import { Pagination } from '@mui/material'; // Импорт компонента Pagination из Material-UI

interface PaginationComponentProps {
	data: {
		page: number;
		lastPage: number;
		total: number;
	};
	setPage: (page: number) => void;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({ data, setPage }) => {
	const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
		setPage(page); // Устанавливаем новую страницу
	};

	return (
		<div className={'w-full flex justify-center items-center'}>
			<Pagination
				page={data.page} // Текущая страница
				count={data.lastPage} // Общее количество страниц
				onChange={handlePageChange} // Обработчик изменения страницы
				color="primary" // Цвет пагинации
				showFirstButton // Показываем кнопку для перехода на первую страницу
				showLastButton // Показываем кнопку для перехода на последнюю страницу
			/>
		</div>
	);
};

export default PaginationComponent;

import { getImgUrl } from '../../shared/utils/getImgUrl.ts';
import { User } from '../../shared/types';
import './user-card.scss';

interface UserCardProps {
	user: User;
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
	return (
		<div className="user-card">
			<div className="avatar-name">
				<img
					src={getImgUrl(user.avatar, user.gender)}
					alt="avatar"
					className="user-card__avatar"
				/>
				<div className="user-card__name">
					<strong>{user.name ?? 'Аноним'}</strong>
					<span>Новичок</span>
				</div>
			</div>
		</div>
	);
};

export default UserCard;

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './pages/Login/Login.tsx';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard.tsx';
import PrivateRoute from './app/PrivateRoute/PrivateRoute.tsx';
import AuthGuard from './app/AuthGuard/AuthGuard.tsx';
import LayoutWrapper from './app/Layout/Layout.tsx';
import { ArticlesPage } from './pages/Articles/ArticlesPage.tsx';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { AppVersion } from './pages/AppVersion/AppVersion.tsx';

const queryClient = new QueryClient();

const router = createBrowserRouter([
	{
		path: '/',
		element: <LayoutWrapper />,
		children: [
			{
				index: true,
				element: <PrivateRoute component={AdminDashboard} />,
			},
			{
				path: 'login',
				element: (
					<AuthGuard>
						<Login />
					</AuthGuard>
				),
			},
			{
				path: 'admin',
				element: <PrivateRoute component={AdminDashboard} />,
			},
			{
				path: 'articles',
				element: <PrivateRoute component={ArticlesPage} />,
			}, {
				path: 'app-version',
				element: <PrivateRoute component={AppVersion} />,
			},
		],
	},
]);

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
			<ToastContainer closeButton={false} toastClassName={'w-fit'} />
		</QueryClientProvider>
	);
}

export default App;

import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { fetchArticles, createArticle, updateArticle, deleteArticle, toggleArticleVisibility } from './api/articleApi';
import { ArticleForm } from './ArticleForm';
import { Article } from './types';
import { ProgressSpinner } from 'primereact/progressspinner';
import PaginationComponent from '../../shared/components/PaginationComponent/PaginationComponent.tsx';
import { toast } from 'material-react-toastify';
import './article.scss';
import { Button } from '@mui/material';

export const ArticlesPage: React.FC = () => {
	const [page, setPage] = useState(1); // Текущее состояние страницы
	const limit = 10; // Лимит статей на страницу
	const queryClient = useQueryClient();

	const { data, isLoading } = useQuery(
		{
			queryKey: ['articles', page],
			queryFn: () => fetchArticles(page, limit),
			placeholderData: keepPreviousData,
		},
	);

	// Mutations for creating, updating, and deleting articles
	const createMutation = useMutation({
		mutationFn: (formData: FormData) => createArticle(formData),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['articles'] });
			toast.dark('Статья создана');
		},
	});

	const updateMutation = useMutation({
		mutationFn: (data: {
			id: number;
			formData: FormData
		}) => updateArticle(data.id, data.formData),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['articles'] });
			toast.dark('Статья обновлена');
		},
	});

	const deleteMutation = useMutation({
		mutationFn: (id: number) => deleteArticle(id),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['articles'] });
			toast.dark('Статья удалена');
		},
	});

	const toggleVisibilityMutation = useMutation(
		{
			mutationFn: (data: { id: number; isVisible: boolean }) => toggleArticleVisibility(data.id, data.isVisible),
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ['articles'] });
			},
		},
	);

	// Handle submit for creating and updating articles
	const handleCreateArticle = (formData: FormData) => {
		createMutation.mutate(formData);
	};

	const handleUpdateArticle = (id: number, formData: FormData) => {
		updateMutation.mutate({ id, formData });
	};

	const handleDeleteArticle = (id: number) => {
		if (window.confirm('Are you sure you want to delete this article?')) {
			deleteMutation.mutate(id);
		}
	};

	const handleToggleVisibility = (id: number, isVisible: boolean) => {
		toggleVisibilityMutation.mutate({ id, isVisible });
	};

	if (isLoading) return <ProgressSpinner />; // Показываем индикатор загрузки, если данные еще загружаются

	return (
		<div>
			{/* Форма для создания новой статьи */}
			<ArticleForm onSubmit={handleCreateArticle} className="article-wrapper" creationMode />

			{/* Список статей */}
			<div className="space-y-4 mt-8">
				{data?.articles.map((article: Article) => (
					<div key={article.id} className="article-wrapper">

						<ArticleForm
							onSubmit={(formData: FormData) => handleUpdateArticle(article.id, formData)}
							initialData={article} className={'mb-3'}
						/>
						<div className="mt-2">Просмотры: {article.views}</div>
						<div className="my-2">Статус: {article.isVisible ? 'Видима' : 'Скрыта'}</div>

						<div className={'flex gap-4'}>
							<Button variant={'contained'}
											onClick={() => handleToggleVisibility(article.id, !article.isVisible)}
							>{article.isVisible ? 'Скрыть' : 'Показать'}</Button>

							<Button variant={'contained'} color={'error'}
											onClick={() => handleDeleteArticle(article.id)}
							>Удалить</Button>
						</div>

					</div>
				))}
			</div>

			{/* Управление пагинацией */}
			{data && <div className={'w-full flex justify-center items-center mt-4'}>
				<PaginationComponent setPage={setPage} data={data} />
			</div>}
		</div>
	);
};

import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Cookies from 'js-cookie';
import { login } from '../../shared/api/api.config.ts';
import './Login.scss';
import CustomInput from '../../shared/components/CustomInput/CustomInput.tsx';
import { Button } from '@mui/material';


type FieldType = {
	email: string;
	password: string;
};

const Login = () => {
	const { register, handleSubmit, formState: { errors } } = useForm<FieldType>();
	const [loading, setLoading] = React.useState(false);

	const onSubmit: SubmitHandler<FieldType> = async (values) => {
		setLoading(true);
		try {
			const tokens = await login(values.email, values.password);
			Cookies.set('accessToken', tokens.accessToken, { expires: 1, secure: true });
			Cookies.set('refreshToken', tokens.refreshToken, { expires: 7, secure: true });

			// Перенаправление на админку
			window.location.href = '/';
		} catch (error) {
			console.error('Login failed', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="login-container">
			<div className="login-logo">
				<span>UDZU</span>
			</div>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<CustomInput
						type="email"
						label={'Почта'}
						{...register('email', { required: 'Введите корректный email!' })}
						placeholder="Введите почту" errorText={errors.email ? errors.email.message : null}
					/>
				</div>

				<div className="form-item">
					<CustomInput label={'Пароль'}
											 type="password"
											 {...register('password', { required: 'Введите корректный пароль!' })}
											 placeholder="Введите пароль" errorText={errors.password ? errors.password.message : null}
					/>
				</div>

				<div className="form-item">
					<Button variant={'contained'} type="submit" disabled={loading}>Войти</Button>
				</div>
			</form>
		</div>
	);
};

export default Login;

import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	palette: {
		mode: 'dark', primary: {
			main: 'rgb(114, 119, 240)',
		},
		background: {
			paper: 'rgb(38, 47, 60)', default: 'rgb(25, 33, 42)'
		},
	},
});

export default theme;

import React from 'react';
import './CustomInput.scss';

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	errorText?: string | null;
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
	({ label, errorText, ...props }, ref) => {
		return (
			<div>
				{label && <label>{label}</label>}
				<input className={'input mt-2'} ref={ref} {...props} />
				<div className={'flex h-[20px] text-red-500 mt-1'}>
					{errorText && <span>{errorText}</span>}
				</div>
			</div>
		);
	},
);

export default CustomInput;

import { useEffect, useState } from 'react';
import { fetchUsers } from './api/fetchUsers.api.ts';
import { User } from '../../shared/types';
import UserCard from '../../entities/UserCard/UserCard.tsx';
import './user-list.scss';

const UserList: React.FC = () => {
	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		const loadUsers = async () => {
			const data = await fetchUsers(1, 10);
			setUsers(data.users);
		};

		loadUsers();
	}, []);

	return (
		<div className="user-card__wrapper">
			{users.map((user) => (
				<UserCard key={user.id} user={user} />
			))}
		</div>
	);
};

export default UserList;

import { api } from '../../../shared/api/api.config.ts';
import { User } from '../../../shared/types';

interface UserResponse {
	message: string;
	users: User[];
}


export const fetchUsers = async (page: number = 1, limit: number = 10): Promise<UserResponse> => {
	const response = await api.get<UserResponse>(`/user/admin/list`, {
		params: {
			page,
			limit,
		},
	});
	return response.data;
};

import { Outlet, useLocation } from 'react-router-dom';
import './Layout.scss';
import NavBar from '../../shared/components/Sidebar/Sidebar.tsx'; // Подключаем стили
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';

const LayoutWrapper = () => {
	const location = useLocation();
	const isLoginPage = location.pathname === '/login'; // Проверка на страницу логина

	return (
		<div className={!isLoginPage ? 'layout-container' : ''}>
			{!isLoginPage && (
				<aside>
					<NavBar />
				</aside>
			)}
			<main className={!isLoginPage ? 'layout' : ''}>
				<Outlet />
			</main>
		</div>
	);
};

export default LayoutWrapper;
